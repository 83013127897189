import logo from "./logo2.png";
import "./App.css";
import { useState } from "react";

function App() {
  const [randomText, setRandomText] = useState("");
  const [thisSection, setThisSection] = useState("about");

  const projectLinks = ["https://www.globalfitcollective.com/", "https://www.globalfitcollective.com/"];

  const handleLogoClick = (e, projectNumber) => {
    e.preventDefault();
    console.log("clicked logo " + projectNumber);
    window.open(projectLinks[1], "_blank");
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* {randomText} */}
        <div className="about">
          <div className="aboutBox">
            <div className="aboutBoxColumnImage">
              <div className="aboutLogo">
                <a href="https://twitter.com/curi0n" target="_blank">
                  <img className="aboutLogoImg" src="https://pbs.twimg.com/profile_images/1649492655812780037/8OXYIgGI_400x400.jpg" />
                </a>
              </div>
            </div>
            <div className="projectBoxColumnDescription">
              <div className="aboutBlock">
                <h2>whoami</h2>
                <p className="projectDescription">> bioengineer by day, web3 dev by night (and also day) </p>
                {/* <p className="projectDescription">> 8000+ ETH in trading volume on deployed contracts</p> */}
                {/* <p className="projectDescription">> trying to find the meaning of life by giving life meaning</p> */}
              </div>
              <div className="aboutBlock">
                <h2>services</h2>
                <p className="projectDescription">> solidity smart contract development on all EVM chains</p>
                {/* <p className="projectDescription">> fungible and non-fungible tokens</p> */}
                <p className="projectDescription">> custom protocol design</p>
                <p className="projectDescription">> smart contract security and gas-efficiency audits</p>
              </div>
              {/* <div className="aboutBlock">
                <h2>pricing</h2>
                <p className="projectDescription">> dependent on the scope of the project</p>
                <p className="projectDescription">> ERC20, ERC721, and ERC1155 contracts start at 3 ETH</p>
              </div> */}
              <div className="aboutBlock">
                <h2>contact</h2>
                <p className="projectDescription">> email: hi@curion.io</p>
                <p className="projectDescription">
                  > twitter:{" "}
                  <a href="https://twitter.com/curi0n" target="_blank">
                    @curi0n
                  </a>
                </p>
                <p className="projectDescription">
                  > github:{" "}
                  <a href="https://github.com/curi0n-s" target="_blank">
                    curi0n-s
                  </a>
                </p>
              </div>
              <div className="aboutBlockEnd"></div>
            </div>
          </div>

          
          {/* <div className="projectBox">
            <div className="projectBoxColumnImage">
              <div className="projectLogo">
                <a href="https://globalfitcollective.com" target="_blank">
                  <img className="projectLogoImg" src="https://pbs.twimg.com/profile_images/1610017703011684352/Az29REoP_400x400.jpg"></img>
                </a>
              </div>
            </div>

            <div className="projectBoxColumnDescription">
              <h2>
                the global fit club{" "}
                <a href="https://etherscan.io/address/0xf750e454b5afc78f8c2ad2b471798763319d5c9e" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
              </h2>
              <p className="projectDescription">> a novel M2E platform that offers various fitness-related cost-saving perks to holders</p>
            </div>
          </div> */}

          {/* <div className="projectBox">
            <div className="projectBoxColumnImage">
              <div className="projectLogo">
                <a href="https://twitter.com/Karrot_gg" target="_blank">
                  <img className="projectLogoImg" src="https://pbs.twimg.com/profile_images/1642708729891110914/drvbmO0j_400x400.jpg"></img>
                </a>
              </div>
            </div>

            <div className="projectBoxColumnDescription">
              <h2>
                karrot{" "}
                <a href="https://etherscan.io/address/0x54C61E0A2cE6d5A70c042df1604c04a36c58926f" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0x08f2bF3a0C9F674765DFE44A46B3cC9264b80944" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0x6c08b8E19c12566c1973EdA880a653b7638B940d" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0x7bF4AC20D1F4B084f4Bfe1Ced5E99B0e298759B1" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0x27B076a94c2CaEe64cf8404c9e79eEb174F6826A" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
              </h2>
              <p className="projectDescription">> a game of karrot and rabbits who want to steal them...a protocol based around a debasing token, its protection, and its theft</p>
            </div>
          </div> */}

          {/* <div className="projectBox">
            <div className="projectBoxColumnImage">
              <div className="projectLogo">
                <a href="https://twitter.com/thependings" target="_blank">
                  <img className="projectLogoImg" src="https://pbs.twimg.com/profile_images/1607529143968710656/USuCJPg-_400x400.jpg"></img>
                </a>
              </div>
            </div>
            <div className="projectBoxColumnDescription">
              <h2>
                the pendings{" "}
                <a href="https://etherscan.io/token/0x79c2dbc3ced9b873ec0e58d294313c7194c6c4a3" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0x7be542c186f52b3ed50666b11ef6bfc3bb4ce44c" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0xd4bc25a1c0a77c8974020bbddb983829174bc324" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0xe05d3e4435e6bd0f2c10754d956580ecb67c1d37" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
              </h2>
              <p className="projectDescription">> an art curation ecosystem with varied and deflationary economics</p>
            </div>
          </div> */}

          {/* <div className="projectBox">
            <div className="projectBoxColumnImage">
              <div className="projectLogo">
                <a href="https://theconnors.xyz" target="_blank">
                  <img className="projectLogoImg" src="https://bllcrypto.github.io/classic_connor.png" />
                </a>
              </div>
            </div>
            <div className="projectBoxColumnDescription">
              <h2>
                the connors{" "}
                <a href="https://etherscan.io/token/0x0be697ce3f41508510ccf502c55c37990274f05e" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
                <a href="https://etherscan.io/address/0x6723e121d07a2c303da5d0a7857a5788e57cbc4f" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
              </h2>
              <p className="projectDescription">> i ain't buying this shit...</p>
            </div>
          </div> */}

          {/* <div className="projectBox">
            <div className="projectBoxColumnImage">
              <div className="projectLogo">
                <a href="https://timsg.xyz" target="_blank">
                  <img className="projectLogoImg" src="https://openseauserdata.com/files/d0f49b987d26a6b32a1c1954006dc6ac.svg" />
                </a>
              </div>
            </div>
            <div className="projectBoxColumnDescription">
              <h2>
                TIMsg{" "}
                <a href="https://etherscan.io/address/0x32ca5da380bde9fbbe838585bd6ab678f0a44dcf" target="_blank">
                  <img className="projectLogoImg2" src="https://pbs.twimg.com/profile_images/1296736608482283520/6mDtyT6V_400x400.jpg"></img>
                </a>
              </h2>
              <p className="projectDescription">> TIM sends Tiny Immutable Messages via gas-efficient ERC1155D NFT. Fully on-chain image generation</p>
            </div>
          </div> */}


        </div>
      </header>

      <footer className="App-footer">
        <div className="footer-content">
          <p>
            <a href="https://twitter.com/curi0n" target="_blank">
              curion.eth
            </a>
            , 2023
          </p>
          {/* <p>
            <a>terms of service</a>
          </p> */}
        </div>
      </footer>
    </div>
  );
}

export default App;
